import React, { useState } from 'react';
import Slide1 from './Images/Slide1.jpg';
import Slide2 from './Images/Slide2.jpg';
import './Main.css';
import './Frame2.css';

export default function Frame2() {
    const [currentSlide, setCurrentSlide] = useState(0);
    const slides = [
        { src: Slide1, title: "Financial Adventure", desc:`Embark on a rewarding journey with tailored financial solutions, guided by experts who navigate the complexities of the market with ease. Explore new opportunities and gain fresh perspectives on your financial future, ensuring a smooth and exhilarating path toward your goals.`, className: "slide1" },
        { src: Slide2, title: "Effortless Funding", desc:`Start your journey with our quick and efficient services. Our dedicated team ensures a smooth and streamlined process, getting you the funds you need without the hassle. Experience a stress-free approach that empowers you to achieve your financial goals in no time.`, className: "slide2" }
    ];

    const handlenextClick = () => {
        setCurrentSlide((prev) => (prev + 1) % slides.length);
    };

    const handleprevClick = () => {
        setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
    };

    return (
        <>
            <div id="frame2">
                <div id="f2b1">
                    <div id="f2b1b1">
                        <div className="boxheads1">Experience</div>
                        <div className='boxheads2'>{slides[currentSlide].title}</div>
                    </div>
                    <div className="boxheadsdesc">{slides[currentSlide].desc}</div>
                </div>
                <div id="f2b2">
                    <div id="f2b2sliderbtnprev" className="f2b2sliderbtn" onClick={handleprevClick}>&larr;</div>
                    <div id="f2b2slider">
                        <img src={slides[currentSlide].src} alt="" id="slide" className={slides[currentSlide].className} />
                    </div>
                    <div id="f2b2sliderbtnnext" className="f2b2sliderbtn" onClick={handlenextClick}>&rarr;</div>
                </div>
            </div>
        </>
    );
}
