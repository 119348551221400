import React from 'react';
import Abframe1 from './Abframe1';
import Ppframe2 from './Ppframe2';
import background from './Images/pk.jpg';

const About = () => {
    return (
        <div>
            <Abframe1 background={background} head1={"Discover"} head2={"Unveil Our Lending Options"} desc={`Unlock the potential of your finances with our carefully curated loan packages. Whether you're seeking a quick loan for immediate needs or looking to consolidate debt with our balance transfer options, we have the ideal solution for you. Experience financial freedom with competitive interest rates, flexible terms, and personalized service. Let SIMCO Fincap help you take control of your financial journey and achieve your goals effortlessly. Choose the loan that suits you and start building a brighter financial future today!`}></Abframe1>
            <Ppframe2/>

        </div>
    );
};

export default About;