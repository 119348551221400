import './App.css';
import React, { useState, useEffect } from 'react';
import Navbar from './Components/Navbar';
import AdminNavbar from './Components/AdminNavbar';
import img1 from './Components/Images/pl.jpg';
import img2 from './Components/Images/bl.jpg';
import img3 from './Components/Images/bt.jpg';
import About from './Components/About';
import Desert from './Components/desert';
import Packages from './Components/Packages';
import Home from './Components/Home';
import Dashboard from './Components/Dashboard';
import AddReviews from './Components/AddReviews';
import Upackages from './Components/upackages';
import Login from './Components/Login';
import Contact from './Components/Contact';
import Footer from './Components/footer';
import UploadImages from './Components/upload_img';
import Gallery from './Components/gallery';
import Jaisalmer from './Components/jaisalmer';
import WhatsAppButton from './Components/WhatsAppButton';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  const [head1, setHead1] = useState(`Flexible Loans for Life's Big Moments`);
  const [head2, setHead2] = useState(`Loans That Fit Your Life`);
  const [src, setsrc] = useState(img1);
  const images = [img1, img3, img2];
  let currentIndex = 0;

  useEffect(() => {
    const interval1 = setInterval(() => {
      setHead1((prevHead1) => {
        if (prevHead1 === `Flexible Loans for Life's Big Moments`) {
          return 'Empowering Your Business Journey';
        } else if (prevHead1 === 'Empowering Your Business Journey') {
          return 'Move Your Balance, Save on Interest.';
        } else {
          return `Flexible Loans for Life's Big Moments`;
        }
      });
    }, 2000);

    const interval2 = setInterval(() => {
      setHead2((prevHead2) => {
        if (prevHead2 === 'Loans That Fit Your Life') {
          return 'Funding Your Business Growth';
        } else if (prevHead2 === 'Funding Your Business Growth') {
          return 'Lower rates Less stress';
        } else {
          return 'Loans That Fit Your Life';
        }
      });
    }, 2000);

    const interval3 = setInterval(() => {
      setsrc(() => {
        currentIndex = (currentIndex + 1) % images.length;
        return images[currentIndex];
      });
    }, 2000);

    return () => {
      clearInterval(interval1);
      clearInterval(interval2);
      clearInterval(interval3);
    };
  }, []);

  return (
    <Router>
      <Routes>
        {/* Normal User Routes */}
        <Route exact path="/" element={<><Navbar /><Home head1={head1} head2={head2} src={src} /><Footer /></>} />
        <Route exact path="/about" element={<><Navbar /><About /><Footer /></>} />
        <Route exact path="/desert" element={<><Navbar /><Desert /><Footer /></>} />
        <Route exact path="/packages" element={<><Navbar /><Packages /><Footer /></>} />
        <Route exact path="/gallery" element={<><Navbar /><Gallery /><Footer /></>} />
        <Route exact path="/jaisalmer" element={<><Navbar /><Jaisalmer /><Footer /></>} />
        <Route exact path="/Contact" element={<><Navbar /><Contact /><Footer /></>} />

        {/* Admin Routes */}
        <Route exact path="/Admin" element={<Login />} />
        <Route exact path="/dashboard" element={<><AdminNavbar /><Dashboard /></>} />
        <Route exact path="/addreviews" element={<><AdminNavbar /><AddReviews /></>} />
        <Route exact path="/upackages" element={<><AdminNavbar /><Upackages /></>} />
        <Route exact path="/uploadimages" element={<><AdminNavbar /><UploadImages /></>} />
      </Routes>
      <WhatsAppButton /> {/* Place the floating WhatsApp button here */}
    </Router>
  );
}

export default App;
