import React, { useEffect, useState } from 'react';
import './Main.css';
import './frame5.css';
import Slide2 from './Images/person.jpg';

export default function Frame5() {
    const [reviews, setReviews] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        // Fetch reviews from the server
        fetch('/backend/get_reviews.php') // Ensure this path matches your file location
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    setReviews(data.reviews);  // Use the reviews key from the response
                } else {
                    console.error("No reviews found");
                }
            })
            .catch(error => console.error('Error fetching reviews:', error));
    }, []);

    // Set default values to avoid empty fields if reviews haven't loaded yet
    const currentReview = reviews.length > 0 ? reviews[currentIndex] : {
        name: 'Neha Saharan',  // Fallback name
        review: 'The spaces are comfortable and clean, and the location is conveniently close to the sandy desert. Our stay was well-cared for, and the nighttime music and dance performances were a fantastic addition. The jeep and camel rides come highly recommended.',
        location: 'Mumbai',
        stars: '',
        image: Slide2  // You can add a default image
    };

    const handlenextClick = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % reviews.length);
    };

    const handleprevClick = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + reviews.length) % reviews.length);
    };

    return (
        <>
            <div id="frame5">
                <div id="f5b1">
                    <div className="boxheads1">See</div>
                    <div className='boxheads2'>What Our Clients Are Saying</div>
                    <div className="boxheadsdesc">Hear Directly from Those Who’ve Trusted Us</div>
                    <div id="forbtnflex">
                        <div id="f2b2sliderbtnprev" className="f2b2sliderbtn2" onClick={handleprevClick}>&larr;</div>
                        <div id="f2b2sliderbtnnext" className="f2b2sliderbtn2" onClick={handlenextClick}>&rarr;</div>
                    </div>
                </div>
                <div id="f5b2">
                    <div id="f5b21">
                        <div className="boxheaddesc edit">{currentReview.review}</div>
                    </div>
                    <div id="f5b22">
                        <div id="f5b22img">
                        <img src={`/backend/review_images/${currentReview.image}`} alt={currentReview.name} className='rrimg' />
                        </div>
                        <div id="f5b223">
                            <div className="boxheads2">{currentReview.name}</div> {/* Using name instead of customer_name */}
                            <div className="boxheads1">{currentReview.location}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
