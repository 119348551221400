// import React, { useState } from 'react';
import './Main.css';
import './Frame4.css';

export default function Frame4() {
    return (
        <>
            <div id="frame4">
                <div id="f4b2">
                    <div id="f4b2b1">
                        <div className="boxheads1">Explore</div>
                        <div className='boxheads2'>Benefits of Choosing Us</div>
                    </div>
                    <div id="f4b2b2">
                        <div className="boxheadsdesc">Embark on a rewarding financial journey with our thoughtfully designed loan options, filled with tailored solutions, valuable insights, and opportunities to achieve your goals every step of the way.</div>
                    </div>
                </div>
                <div id="f4b3">
                    {/* <div id="forcards"> */}
                        <div className='f4b3cards' id='card1'>
                            <div className="f4b3cardheads">Competitive Interest Rates</div>
                            <div className="f4b3carddesc">Benefit from appealing interest rates that make your borrowing experience more cost-effective and help you save over time.</div>
                        </div>
                        <div className='f4b3cards' id='card2'>
                            <div className="f4b3cardheads">Simplified Online Application</div>
                            <div className="f4b3carddesc">Our straightforward online application makes it easy to apply for loans at your convenience, anytime and anywhere.</div>
                        </div>
                        <div className='f4b3cards' id='card3'>
                            <div className="f4b3cardheads">Quick Approval Process</div>
                            <div className="f4b3carddesc">Enjoy rapid loan approvals, ensuring you can access the funds you need promptly and without delays.</div>
                        </div>
                        <div className='f4b3cards' id='card4'>
                            <div className="f4b3cardheads">Minimal Documentation Requirements</div>
                            <div className="f4b3carddesc">We streamline the process with reduced documentation, allowing you to secure your loan with ease and less hassle.</div>
                        </div>
                    {/* </div> */}
                </div>
            </div>
        </>
    )
}
