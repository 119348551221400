import React, { useState } from 'react';
import './Main.css';
// import './Frame1.css';
import Slide1 from './Images/tours.jpg';
import Slide2 from './Images/premium.jpg';

export default function Frame1() {
    const slidesData = [
        {
            head: "Our Vision",
            desc: "At SIMCO FinCap, we aspire to transform the financial landscape by leading the way in sustainable and ethical investment practices. Our goal is to create a future where financial success aligns with social responsibility and environmental care. Through our dedication to innovation, honesty, and inclusivity, we aim to encourage positive change in business operations and investment strategies. By promoting a culture of transparency and accountability, we seek to empower individuals and organizations to make well-informed choices that benefit both current and future generations. Together, we envision a world where finance acts as a catalyst for good, fostering progress toward a more sustainable and equitable society.",
            img: Slide1
        },
        {
            head: "Our Mission",
            desc: `SIMCO FinCap is dedicated to providing accessible and reliable loan services tailored to our clients’ needs. Whether you're a small business looking to grow, an individual in need of financial support, or a community organization aiming to make an impact, we are here for you. With competitive rates, flexible terms, and a focus on customer satisfaction, we strive to make the borrowing process smooth and straightforward. Our experienced team is committed to understanding your unique situation and delivering customized loan solutions. Trust SIMCO FinCap to help you take the next step toward achieving your goals.

Our mission at SIMCO FinCap is to lead in sustainable finance, driving positive change through responsible investing. We are committed to offering innovative financial solutions that not only generate returns but also contribute to a better world. By adhering to ethical principles and promoting collaboration, we empower our clients to make informed investment decisions that align with their values. Together, we aim to create a sustainable financial ecosystem that benefits all stakeholders and shapes a brighter future.`,
            img: Slide2
        }
    ];

    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

    const handlenextClick = () => {
        setCurrentSlideIndex((prevIndex) => (prevIndex + 1) % slidesData.length);
    };

    const handleprevClick = () => {
        setCurrentSlideIndex((prevIndex) => (prevIndex - 1 + slidesData.length) % slidesData.length);
    };

    return (
        <>
            <div id="abframe2">
                <div id="abf2b1">
                    {/* <div className="boxheads1">Experience</div> */}
                    <div className='boxheads2'>{slidesData[currentSlideIndex].head}</div>
                    <div id="abf2img"><img src={slidesData[currentSlideIndex].img} alt="" id='slideabf' /></div>
                </div>
                <div id="abf2b2">
                    <div className="boxheadsdesc" id='desc'>{slidesData[currentSlideIndex].desc}</div>
                    <div id="forbtnflex">
                        <div id="f2b2sliderbtnprev" className="f2b2sliderbtn2" onClick={handleprevClick}>&larr;</div>
                        <div id="f2b2sliderbtnnext" className="f2b2sliderbtn2" onClick={handlenextClick}>&rarr;</div>
                    </div>
                </div>
            </div>
        </>
    );
}
