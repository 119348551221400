import React, { useState, useEffect } from 'react';
// import { Link } from "react-router-dom";
// import fb from './Images/fb.png';
// import insta from './Images/insta.png';
// import google from './Images/google.png';
import email from './Images/email.png';
import city from './Images/city.png';
import phone from './Images/phone.png';
import location from './Images/location.png';
import './footer.css';
// import award from './Images/award.jpg'; // Import the new award image

export default function Footer() {
    // State to track window width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Effect to handle window resizing
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <>
            <div id="footer">
                <div id="f11">
                    <div id="f1">
                    <div className='rowf'>
                            <a href="tel:+918829081142">
                                <img src={phone} alt="Call" className='socialLinksImg' />
                            </a>
                            <div className='sstxt'>
                                <div className='sstxt1'>Phone Number</div>
                                <a href="tel:+918829081142" className='sstxt2' style={{ textDecoration: 'none', color: 'inherit' }}>
                                    +91 8829081142
                                </a>
                            </div>
                        </div>
                    <div className='rowf'>
                            <img src={email} alt="" className='socialLinksImg' />
                            <div className='sstxt'>
                                <div className='sstxt1'>Mail</div>
                                <div className='sstxt2'>info@simcofincap.in</div>
                            </div>
                        </div>
                    <div className='rowf'>
                            <img src={location} alt="" className='socialLinksImg' />
                            <div className='sstxt'>
                                <div className='sstxt1'>Location</div>
                                <div className='sstxt2'>E-44, Amrit Puri Centre, Transport Nagar, Transport Office Of India, Jaipur - 302004</div>
                            </div>
                        </div>
                    </div>

                    {/* <div style={responsiveStyles.f3}>
                        <img src={award} alt="TripAdvisor Award" style={responsiveStyles.awardImage} />
                    </div> */}

                    {/* <div style={responsiveStyles.f2}>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3559.8774505706515!2d70.54076247522188!3d26.84384987668821!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3947bc258f1f1b3b%3A0x9c690201e406e12b!2sDesert%20Pearl%20Camp%20%26%20Resort!5e0!3m2!1sen!2sin!4v1726810068972!5m2!1sen!2sin" style={responsiveStyles.iframe} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div> */}
                </div>

                <div id='f22'>
                    <div>Copyright © 2023 SIMCO Fincap - All Rights Reserved.</div>
                    <div>Privacy Policy | Terms & Conditions</div>
                </div>
            </div>
        </>
    )}
