import React from 'react';
import Abframe1 from './Abframe1';
import Abframe2 from './Abframe2';
import background from './Images/about.jpg';

const About = () => {
  return (
    <div>
      <Abframe1 background={background} head1={"Welcome to"} head2={"SIMCO FinCap"} desc={"SIMCO Fincap provides a unique opportunity to enhance your financial journey with tailored loan solutions designed just for you. Our services are strategically located to ensure easy access, allowing you to manage your finances efficiently. With our expert team by your side, you’ll experience a seamless process that gives you peace of mind and confidence in your financial decisions. Surrounded by a wealth of resources and support, we empower you to achieve your financial goals and create a secure future. Experience financial freedom with SIMCO Fincap and take the next step toward your aspirations."}></Abframe1>
      <Abframe2></Abframe2>

    </div>
  );
};

export default About;