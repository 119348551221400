import React, { useState, useEffect } from 'react';
import './Main.css';
import './Ppframe2.css';

export default function Frame1() {
    const [packages, setPackages] = useState([]);
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [showForm, setShowForm] = useState(false);
    const [customerName, setCustomerName] = useState('');
    const [customerEmail, setCustomerEmail] = useState('');
    const [customerPhone, setCustomerPhone] = useState('');
    const [bookingDate, setBookingDate] = useState('');
    const [numGuests, setNumGuests] = useState(1);
    const [specialRequests, setSpecialRequests] = useState('');
    const [customerMessage, setCustomerMessage] = useState('');

    // Fetch packages from the server
    const fetchPackages = async () => {
        try {
            const response = await fetch('/backend/get_packages.php');
            const data = await response.json();

            if (data.success) {
                setPackages(data.packages);
            } else {
                console.error(data.message);
            }
        } catch (error) {
            console.error("Error fetching packages:", error);
        }
    };

    useEffect(() => {
        fetchPackages();
    }, []);

    const handleBookingClick = (pkg) => {
        setSelectedPackage(pkg);
        setShowForm(true);
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        const formData = {
            packageId: selectedPackage.package_id,
            customerName,
            customerEmail,
            customerPhone,
            bookingDate,
            numGuests,
            specialRequests,
            customerMessage,
        };

        try {
            const response = await fetch('/backend/submit_booking.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const data = await response.json();
            if (data.success) {
                alert("Booking submitted successfully.");
                setShowForm(false);  // Hide the form after successful submission
                // Reset form fields
                setCustomerName('');
                setCustomerEmail('');
                setCustomerPhone('');
                setBookingDate('');
                setNumGuests(1);
                setSpecialRequests('');
                setCustomerMessage('');
            } else {
                alert("Failed to submit booking. Please try again.");
            }
        } catch (error) {
            console.error("Error submitting booking:", error);
            alert("An error occurred. Please try again.");
        }
    };

    return (
        <>
            <div id="ppframe">
                {packages.map((pkg) => (
                    <div className="cardbox" key={pkg.package_id}>
                        <div className="cardhead2">{pkg.name}</div>
                        <div className="cardhead1 ppcardhead">{pkg.price}</div>
                        <div className="cardboxp2">
                            <img
                                src={`/backend/uploads/${pkg.image}`}
                                alt={pkg.name}
                                className="cardimg"
                            />
                            <div className="cardrest">
                                <div className="carddesc">{pkg.description}</div>
                                <div className="cardlists">
                                    <ul>
                                        {pkg.features.split(';').map((feature, index) => (
                                            <li key={index}>{feature.trim()}</li>
                                        ))}
                                    </ul>
                                </div>
                                <button className='bookbtn' onClick={() => handleBookingClick(pkg)}>Apply Now</button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {/* Booking Form Modal */}
            {showForm && (
                <div className="booking-form-overlay">
                    <div className="booking-form-card">
                        <h2>Apply Now</h2>
                        <form onSubmit={handleFormSubmit} id='formflex'>
                            <div className="rowfff">
                                <input
                                    type="text"
                                    placeholder="Name"
                                    value={customerName}
                                    onChange={(e) => setCustomerName(e.target.value)}
                                    required
                                />
                                <input
                                    type="email"
                                    placeholder="Email"
                                    value={customerEmail}
                                    onChange={(e) => setCustomerEmail(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="rowfff">
                                <input
                                    type="tel"
                                    placeholder="Phone"
                                    value={customerPhone}
                                    onChange={(e) => setCustomerPhone(e.target.value)}
                                    required
                                />
                                <select name="opp" className="opp">
                                    <option value="Personal Loan">Personal Loan</option>
                                    <option value="Business Loan">Business Loan</option>
                                    <option value="Agriculture Loan">Agriculture Loan</option>
                                    <option value="Balance Transfer">Balance Transfer</option>
                                </select>
                            </div>
                            <div className="rowfff">
                                <input
                                    type="number"
                                    placeholder="Loan Amount"
                                    value={numGuests}
                                    min="1"
                                    onChange={(e) => setNumGuests(Number(e.target.value))}
                                    required
                                />
                                <select name="opp2" className="opp">
                                    <option value="Salaried">Salaried</option>
                                    <option value="Self-Employed">Self-Employed</option>
                                </select>
                            </div>
                            <div className="rowfff">
                                <textarea
                                    id='tt'
                                    placeholder="Additional Message"
                                    value={customerMessage}
                                    onChange={(e) => setCustomerMessage(e.target.value)}
                                ></textarea>
                            </div>
                            <div className="rowfff" id='newfff'>
                                <button type="submit" className="submit-booking-btn ">Submit</button>
                                <button className="close-form-btn" onClick={() => setShowForm(false)}>Close</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </>
    );
}
