import './Main.css';
import React, { useState } from 'react';
// import qrCode from './Images/qrcode1.jpg'; // Import the QR code image

export function Frame4() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('name', name);
        formData.append('email', email);
        formData.append('phone', phone);
        formData.append('message', message);

        try {
            const response = await fetch('/backend/submit_enquiry.php', {
                method: 'POST',
                body: formData,
            });

            const data = await response.json();
            if (data.success) {
                alert("Your query has been submitted! Desert Pearl Resort will contact you soon.");
                // Reset form fields
                setName('');
                setEmail('');
                setPhone('');
                setMessage('');
            } else {
                alert("Failed to submit your query. Please try again.");
            }
        } catch (error) {
            console.error("Error submitting the query:", error);
            alert("An error occurred. Please try again.");
        }
    };

    return (
        <>
            <div className="ff">
                <div className="ff1">
                    <div className="boxheads1">Have a question?</div>
                    <div className="boxheads2">Send Us Your Query</div>
                    <div className="boxheaddesc">Fill out the form below and we'll get back to you shortly.</div>
                </div>
                <div className="ff2">
                    <form onSubmit={handleSubmit} className="contact-form">
                        <div className="row">
                            <input
                                type="text"
                                id='fname'
                                className='ftxt'
                                placeholder='Name'
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                            <input
                                type="email"
                                name="femail"
                                id="femail"
                                className='ftxt'
                                placeholder='Email'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div className="row">
                            <input
                                type="text"
                                id='fphone'
                                className='ftxt'
                                placeholder='Contact'
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                required
                            />
                        </div>
                        <div className="row">
                            <textarea
                                name="farea"
                                id="farea"
                                placeholder='Message'
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                required
                            ></textarea>
                        </div>
                        <div className="row">
                            <button className='bookbtn' type="submit" id='submitff'>Submit</button>
                        </div>
                    </form>
                </div>
            </div>
            {/* <div className="ff qr-section">
                <div className="ff1">
                    <div className="boxheads1">Or Book Your Stay by</div>
                    <div className="boxheads2">Scanning The QR Code</div>
                    <div className="boxheaddesc">Scan the QR code to easily make your reservation and start your desert adventure.</div>
                </div>
                <div className="ff2">
                    <img src={qrCode} alt="QR Code" className="qr-image" />
                </div>
            </div> */}
        </>
    );
}
